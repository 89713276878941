<template>
    <div class="relative my-10 py-7 ">
        <div class="absolute bottom-0 inset-4 bg-gradient-to-r from-cyan-400 to-sky-500 shadow-sm lg:shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl"></div>
        <div class="relative bg-white shadow-lg rounded-3xl ">
            <div class="px-4 py-5 sm:px-6">
                <div class="flex justify-start">
                    <h1 class="text-2xl uppercase bold font-bold">Tu demanda</h1>
                    <img class="px-2" src="../assets/images/lawyer_86193.png"/>
                </div>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Detalle de tu indemnización proyectada.
                </p>
            </div>
            <div class="border-top">
                <dl>
                    <div v-for="item,key in items" :key="key" class="animated fadeInLeft border-top px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6" :class="{'bg-red-100' : !item.profit }">
                        <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                            {{ item.title }}
                        </dt>
                        <dd class="mt-1 text-sm font-bold sm:mt-0 sm:col-span-2" :class="{'text-red-900' : !item.profit }">
                            {{ item.amount | currency }}
                        </dd>
                    </div>
                    <div class="animated fadeInLeft border-top bg-yellow-100 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                            TOTAL DE TU DEMANDA
                        </dt>
                        <dd class="mt-1 text-sm font-bold sm:mt-0 sm:col-span-2">
                            {{ totalDemand | currency }}
                        </dd>
                    </div>
                    <div v-if="bustosLaw" class="animated fadeInLeft border-top px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                            Nulidad del despido por 12 meses
                        </dt>
                        <dd class="mt-1 text-sm font-bold sm:mt-0 sm:col-span-2">
                            {{ bustosLaw | currency }}
                        </dd>
                    </div>
                    <div class="animated fadeInLeft border-top bg-red-100 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                            NUESTROS HONORARIOS
                        </dt>
                        <dd class="mt-1 text-sm font-bold sm:mt-0 sm:col-span-2 text-red-900">
                            {{ feeLabel }}
                        </dd>
                    </div>
                    <div class="border-top bg-lime-100 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 rounded-b-3xl">
                        <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                            TOTAL DE TU INDEMNIZACIÓN AL FINALIZAR EL JUICIO
                        </dt>
                        <dd class="mt-1 text-sm font-bold text-green-900 sm:mt-0 sm:col-span-2">
                            <div class="grid grid-cols-2">
                                <div class="place-self-start">{{ total | currency }} </div>
                                <div class="place-self-end">
                                    <button
                                        v-on:click="requestAdvice()"
                                        :class="{ 'animated jello': solicitarAsesoria, }"
                                        class="demand-btn"
                                    > 
                                        DEMANDA SIN COSTO
                                    </button>
                                </div>
                            </div>
                        </dd>
                        <span class="text-gray-500 text-xs col-span-3">
                            Los valores expresados se obtienen a partir de la información que proporciona el usuario cuyos montos definitivos serán determinados por el tribunal competente.
                        </span>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CompensationResult',
    // props: {
    //     compensationItems: {},
    // },
    props: ['compensationItems'],
    data(){
        return {
            items: this.compensationItems.items,
            totalDemand: this.compensationItems.totalDemand,
            total: this.compensationItems.totalDemand + this.compensationItems.bustosLaw,
            bustosLaw: this.compensationItems.bustosLaw,
            feeLabel: this.compensationItems.feeLabel,
            solicitarAsesoria: false
        }
    },
    mounted: function () {
        
    },
    methods: {
        requestAdvice:  function () {
            this.$swal.fire({
                title: 'Felicitaciones!',
                text: "Su solicitud ha sido ingresada, pronto nos pondremos en contacto con usted.",
                icon: 'success',
                // confirmButtonText: 'Acepto',
                // showCancelButton:"true",
                // cancelButtonText: 'Cancelar'
            })
        }
    },
}
</script>

<style scoped>
.border-top {
    @apply border-t border-gray-200 border-solid !important;
}
</style>