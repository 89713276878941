<template>
	<ul class="stepper" data-mdb-stepper="stepper">
		<li  v-for="step,key in steps" :key="key"
			:class="{
				'stepper-step': true,
				'stepper-active': calculatorStore.currentStep === step,
				'stepper-completed': calculatorStore.currentStep > step,
			}"
			v-on:click="setStep(step)"
		>
			<div class="stepper-head">
				<span class="stepper-head-icon"> {{ step }} </span>
				<span class="stepper-head-text"> Paso {{ step }} </span>
			</div>
		</li>
	</ul>
</template>

<script>
import { mapStores } from 'pinia'
import { useCalculatorStore } from '../store/store'

export default {
	name: 'StepsNav',
	props: [ 'steps' ],
	components: {
	},
	methods: {
		setStep: function ( step ) {
			if ( this.calculatorStore.currentStep > step )
				this.calculatorStore.currentStep = step
		}
	},
	computed: {
		...mapStores(useCalculatorStore)
	}
}
</script>

<style>

</style>