<template>
  <div id="app" class="lg:mx-auto">
    <CalculadoraForm/>
  </div>
</template>

<script>
import Vue from 'vue';

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import 'tw-elements'

import CalculadoraForm from './components/CalculadoraForm.vue'

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

export default {
  name: 'App',
  components: {
    CalculadoraForm
  }
}
</script>

<style>
@import '~animate.css/animate.compat.css';
.datepicker-label {
  @apply uppercase font-bold text-sm md:text-base float-left text-gray-500;
}
.label {
  @apply uppercase font-bold text-sm md:text-base float-left text-gray-500 absolute top-2 left-2 transition ease-in-out duration-700 !important;
}
.active-label{
  @apply  origin-top-left transform-gpu -translate-y-8 scale-90 mt-1 md:mt-0 !important;
}
/* Toggle switch */
.toggle-label{
  @apply block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer !important;
}
.toggle-checkbox{
  @apply ring-0 ring-offset-0 focus:ring-0 absolute block w-6 h-6 rounded-full text-white bg-white border-gray-300 border-4 appearance-none cursor-pointer !important;
  background-image: none !important;
  --tw-ring-color: none !important;
}
.toggle-checkbox:checked {
  @apply: right-0 border-green-400 !important;
  right: 0 !important;
  border-color: #68D391 !important;
}
.toggle-checkbox:checked:hover {
  border: 0 !important;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400 !important;
  background-color: #68D391 !important;
}
.toggle-switch{
  @apply relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in !important;
}
</style>
