<template>
    <div>
        <div class="col-span-12 grid grid-cols-12 gap-1">
            <div class="col-span-12 text-center">
                <span class="font-bold text-xl">RESPONDE ESTAS PREGUNTAS</span>
            </div>
            <hr class="col-span-12 mb-3" />
            <!-- ¿Te deben días de trabajo? -->
            <div class="col-span-12 md:col-span-10 text-left mb-2 md:mb-4">
                <span class="text-lg font-bold" >
                    ¿Te deben días de trabajo?
                </span>
            </div>
            <div class="col-span-12 md:col-span-2 mb-5 md:mb-0">
                <label for="unpaidWorkDaysCheck" class="switch-label">No</label>
                <div class="toggle-switch">
                    <input type="checkbox" name="unpaidWorkDaysCheck" id="unpaidWorkDaysCheck" v-model="calculatorStore.unpaidWorkDaysCheck" class="toggle-checkbox"/>
                    <label for="unpaidWorkDaysCheck" class="toggle-label"></label>
                </div>
                <label for="unpaidWorkDaysCheck" class="switch-label">Si</label>
            </div>
            <transition
                enter-active-class="animated fadeInDown"
                leave-active-class="animated fadeOutUp"
            >
                <div v-if="calculatorStore.unpaidWorkDaysCheck" class="col-span-12 mb-1">
                    <ValidationProvider name="unpaidWorkDays" class="grid grid-cols-12 gap-2" rules="required|numeric" tag="div" v-slot="{ errors, classes }">
                        <div class="col-span-3 md:col-span-2">
                            <div class="relative">
                                <label for="unpaidWorkDays" :class="{ 'label': true, 'active-label': isActive('unpaidWorkDays') }" >
                                    dias
                                </label>
                                <input type="text"
                                    id="unpaidWorkDays"
                                    ref="unpaidWorkDays"
                                    name="unpaidWorkDays"
                                    v-model="calculatorStore.form.unpaidWorkDays"
                                    class="input-text my-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    :class="classes">
                            </div>
                        </div>
                        <div class="col-span-9 md:col-span-10 pt-4">
                            <FormError :attribute_name="'unpaidWorkDays'" :errors_form="errors" />
                        </div>
                    </ValidationProvider>
                </div>
            </transition>
            <hr class="col-span-12 mb-3" />
            <!-- ¿Te deben algún bono ?? -->
            <div class="col-span-12 md:col-span-10 text-left mb-2 md:mb-4">
                <span class="text-lg font-bold" >
                    ¿Te deben algún bono?
                </span>
            </div>
            <div class="col-span-12 md:col-span-2 mb-5 md:mb-0">
                <label for="unpaidBonusCheck" class="switch-label">No</label>
                <div class="toggle-switch">
                    <input type="checkbox" name="unpaidBonusCheck" id="unpaidBonusCheck" v-model="calculatorStore.unpaidBonusCheck" class="toggle-checkbox"/>
                    <label for="unpaidBonusCheck" class="toggle-label"></label>
                </div>
                <label for="unpaidBonusCheck" class="switch-label">Si</label>
            </div>
            <transition
                enter-active-class="animated fadeInDown"
                leave-active-class="animated fadeOutUp"
            >
                <div v-if="calculatorStore.unpaidBonusCheck" class="col-span-12 mb-1">
                    <ValidationProvider name="unpaidBonus" class="grid grid-cols-12 gap-2" rules="required|numeric" tag="div" v-slot="{ errors, classes }">
                        <div class="col-span-12 lg:col-span-6">
                            <div class="relative">
                                <label for="unpaidBonus" :class="{ 'label': true, 'active-label': isActive('unpaidBonus'),'pl-7': !isActive('unpaidBonus') }">
                                    Ingresa el monto
                                </label>
                                <vue-numeric
                                    id="unpaidBonus"
                                    ref="unpaidBonus"
                                    name="unpaidBonus"
                                    v-model="calculatorStore.form.unpaidBonus"
                                    placeholder="$ 0"
                                    separator="."
                                    currency="$"
                                    class="input-text my-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    :class="classes"
                                    >
                                </vue-numeric>
                            </div>
                        </div>
                        <div class="col-span-12 mb-1">
                            <FormError :attribute_name="'unpaidBonus'" :errors_form="errors" />
                        </div>
                    </ValidationProvider>
                </div>
            </transition>
            <hr class="col-span-12 mb-3" />
            <!-- ¿Te deben comisiones? -->
            <div class="col-span-12 md:col-span-10 text-left mb-2 md:mb-4">
                <span class="text-lg font-bold" >
                    ¿Te deben comisiones?
                </span>
            </div>
            <div class="col-span-12 md:col-span-2 mb-5 md:mb-0">
                <label for="unpaidCommissionsCheck" class="switch-label">No</label>
                <div class="toggle-switch">
                    <input type="checkbox" name="unpaidCommissionsCheck" id="unpaidCommissionsCheck" v-model="calculatorStore.unpaidCommissionsCheck" class="toggle-checkbox"/>
                    <label for="unpaidCommissionsCheck" class="toggle-label"></label>
                </div>
                <label for="unpaidCommissionsCheck" class="switch-label">Si</label>
            </div>
            <transition
                enter-active-class="animated fadeInDown"
                leave-active-class="animated fadeOutUp"
            >
                <div v-if="calculatorStore.unpaidCommissionsCheck" class="col-span-12 mb-1">
                    <ValidationProvider name="unpaidCommissions" class="grid grid-cols-12 gap-2" rules="required|numeric" tag="div" v-slot="{ errors, classes }">
                        <div class="col-span-12 md:col-span-6">
                            <div class="relative">
                                <label for="unpaidCommissions" :class="{ 'label': true, 'active-label': isActive('unpaidCommissions'),'pl-7': !isActive('unpaidCommissions') }">
                                    Ingresa el monto
                                </label>
                                <vue-numeric
                                    id="unpaidCommissions"
                                    ref="unpaidCommissions"
                                    name="unpaidCommissions"
                                    v-model="calculatorStore.form.unpaidCommissions"
                                    placeholder="$ 0"
                                    separator="."
                                    currency="$"
                                    class="input-text my-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    :class="classes"
                                    >
                                </vue-numeric>
                            </div>
                        </div>
                        <div class="col-span-12 mb-1">
                            <FormError :attribute_name="'unpaidCommissions'" :errors_form="errors" />
                        </div>
                    </ValidationProvider>
                </div>
            </transition>
            <hr class="col-span-12 mb-3" />
            <!-- ¿Te deben cotizaciones o su pago no corresponde a tu sueldo real? -->
            <div class="col-span-12 md:col-span-10 text-left mb-2 md:mb-4">
                <span class="text-lg font-bold" >
                    ¿Te deben cotizaciones o su pago no corresponde a tu sueldo real?
                </span>
            </div>
            <div class="col-span-12 md:col-span-2 mb-5 md:mb-0">
                <label for="unpaidContributionsCheck" class="switch-label">No</label>
                <div class="toggle-switch">
                    <input type="checkbox" name="unpaidContributionsCheck" id="unpaidContributionsCheck" v-model="calculatorStore.unpaidContributionsCheck" class="toggle-checkbox"/>
                    <label for="unpaidContributionsCheck" class="toggle-label"></label>
                </div>
                <label for="unpaidContributionsCheck" class="switch-label">Si</label>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import FormError from './FormError'
    import { ValidationProvider } from 'vee-validate'
    import './validations/validation.js'
    import VueCurrencyFilter from 'vue-currency-filter'
    import VueNumeric from 'vue-numeric'
    import { mapStores } from 'pinia'
    import { useCalculatorStore } from '../store/store'

    const axios = require('axios');

    Vue.use(VueCurrencyFilter, {
            symbol : '$',
            thousandsSeparator: '.',
            fractionCount: 0,
            fractionSeparator: ',',
            symbolPosition: 'front',
            symbolSpacing: true,
    });

    export default {
        name: 'StepTwo',
        components: {
            FormError,
            VueNumeric,
            ValidationProvider
        },
        beforeMount: function(){
            axios.get('https://mindicador.cl/api/uf').then(response => {
                if( response.status == 200 ) {
                    this.uf = response.data.serie[0].valor
                }
            })
        },
        methods: {
            isActive: function (inputName) {
                if(this.$refs[inputName]){
                    var value = this.$refs[inputName].value
                    if(value !== "") {
                        return true
                    }else{
                        return false
                    }
                }
                return false
            }
        },
        watch: {
            grossSalary: function (val) {
                if(val == 0) {
                    this.grossSalary = ''
                }
            },
            afcDiscount: function (val) {
                if(val == 0) {
                    this.afcDiscount = ''
                }
            }, 
            compensationYears: function (val) {
                if(val == 0) {
                    this.compensationYears = ''
                }
            },
            unpaidBonus: function (val) {
                if(val == 0) {
                    this.unpaidBonus = ''
                }
            }, 
            unpaidCommissions: function (val) {
                if(val == 0) {
                    this.unpaidCommissions = ''
                }
            },
            acceptConditions: function (val) {
                if(val == false) {
                    this.showQuestions = false;
                }
            }, 
        },
        computed: {
            ...mapStores(useCalculatorStore)
        }
    }
    </script>

    Add "scoped" attribute to limit CSS to this component only
    <style>
    .spinner{
    border: solid #b9b8b8;
    border-top: solid white;
    vertical-align: middle;
    }
</style>