
import { defineStore } from 'pinia'

export const useCalculatorStore = defineStore('calculator', {
	state: () => ({
		// flags
		currentStep: 1,
		unpaidCompensationYearsCheck: false,
		noticeMonthCompensationCheck: true,
		unpaidWorkDaysCheck: false,
		unpaidBonusCheck: false,
		unpaidCommissionsCheck: false,
		unpaidContributionsCheck: false,
		showQuestions: false,
		showHelpOnFail: false,
		showLoading: false,
		showResult: false,
		// form
		form:{
			firstname: '',
			lastname: '',
			email: '',
			phoneNumber: '',
			grossSalary: '',
			afcDiscount: '',
			compensationYears: '',
			unpaidCompensationYears: '',
			unpaidWorkDays: '',
			unpaidVacationDays: '',
			unpaidBonus: '',
			unpaidCommissions: '',
			startDate: '',
			startDateMasked: '',
			acceptConditions: null
		},
		unpaidCompensationYearsAmount: 0,
        legalSurcharge: 0
	})
})
