import { extend } from 'vee-validate';
import { required, email, numeric, min, max, min_value, max_value } from 'vee-validate/dist/rules';
import { messages } from './locale/es.json';
import { configure } from 'vee-validate';


extend('email', {
  ...email,
  message: messages.email
});
extend('numeric', {
  ...numeric,
  message: messages.numeric
});
extend('min', {
  ...min,
  message: messages.min
});
extend('max', {
  ...max,
  message: messages.max
});
extend('min_value', {
  ...min_value,
  message: messages.min_value
});
extend('max_value', {
  ...max_value,
  message: messages.max_value
});
extend('required', {
  ...required,
  message: messages.required
});
extend('required_if', {
  ...required,
  message: messages.required
});

configure({
    classes: {
      valid: 'border-green-500',
      invalid: 'border-red-500',
    },
    events: 'blur',
    mode: 'eager'
  })