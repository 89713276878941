<template>
    <transition
                enter-to-class="animated rubberBand"
                leave-to-class="animated fadeOut">
        <div class="text-left mt-1" v-if="Array.isArray(errors_form) && errors_form.length">
            <span class="text-red-500 text-xs font-bold">{{ errors_form[0] }}</span>
        </div>
    </transition>
</template>

<script>
    export default {
        props: ['attribute_name', 'errors_form']
    }
</script>

<style>
</style>