<template>
    <div class="m-2 md:m-10 grid grid-cols-12 lg:gap-20">
        <div id="calculadora-form-container" class="col-span-12 mt-5 md:mt-0" >
            <h1 class="text-white font-extrabold text-2xl">CALCULA EL MONTO DE TU INDEMNIZACIÓN</h1>
        </div>
        <div id="calculadora-form-container" class="col-span-12 mt-5 md:mt-0" >
            <ValidationObserver ref='calculatorFormObserver'>
                <form 
                    id="calculadoraDNE"
                    ref="calculadoraDNE"
                    name="calculadoraDNE"
                    method="POST"
                    @submit.prevent="validateBeforeSubmit"
                    enctype="multipart/form-data"
                    autocomplete="off"
                >
                    <div class="shadow rounded-md bg-white">
                        <div class="px-2 lg:px-6 md:3 pt-6 pb-4 md:pt-10 md:pb-6">
                            <h2 
                                class="text-1xl uppercase bold font-bold text-center"
                                id="title"
                                ref="title"
                            >
                                Ingresa tus datos
                            </h2>
                            <StepsNav class="mb-6" :steps="3" />
                            <transition>
                                <div class="grid grid-cols-12 gap-6" v-show="calculatorStore.currentStep === 1">
                                    <!-- NAME -->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider rules="required" v-slot="{ errors, classes }" tag="div">
                                            <div class="relative">
                                                <label for="firstname" :class="{ 'label': true, 'active-label': isActive('firstname') }">Nombre</label>
                                                <input type="text"
                                                    id="firstname"
                                                    ref="firstname"
                                                    name="firstname"
                                                    v-model="calculatorStore.form.firstname"
                                                    class="input-text mt-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    :class="classes">
                                            </div>
                                            <FormError :attribute_name="'firstname'" :errors_form="errors"></FormError>
                                        </ValidationProvider>
                                    </div>
                                    <!-- LASTNAME -->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider rules="required" v-slot="{ errors, classes }">
                                            <div class="relative">
                                                <label for="lastname" :class="{ 'label': true, 'active-label': isActive('lastname') }" >Apellidos</label>
                                                <input type="text"
                                                    id="lastname"
                                                    ref="lastname"
                                                    name="lastname"
                                                    v-model="calculatorStore.form.lastname"
                                                    class="input-text mt-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    :class="classes">
                                            </div>
                                            <FormError :attribute_name="'lastname'" :errors_form="errors"></FormError>
                                        </ValidationProvider>
                                    </div>
                                    <!-- E-Mail -->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider rules="required" v-slot="{ errors, classes }">
                                            <div class="relative">
                                                <label for="email" :class="{ 'label': true, 'active-label': isActive('email') }" >Email</label>
                                                <input type="email"
                                                    id="email"
                                                    ref="email"
                                                    name="email"
                                                    v-model="calculatorStore.form.email"
                                                    class="input-text mt-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    :class="classes">
                                            </div>
                                            <FormError :attribute_name="'email'" :errors_form="errors"> </FormError>
                                        </ValidationProvider>
                                    </div>
                                    <!-- N° Celular -->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider  name="phoneNumber" rules="required|numeric|min:9|max:9" v-slot="{ errors, classes }">
                                            <div class="relative">
                                                <label for="phoneNumber" :class="{ 'label': true, 'active-label': isActive('phoneNumber') }" >N° Celular</label>
                                                <input type="text"
                                                    id="phoneNumber"
                                                    ref="phoneNumber"
                                                    name="phoneNumber"
                                                    v-model="calculatorStore.form.phoneNumber"
                                                    class="input-text mt-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    :class="classes"
                                                    maxlength="9"
                                                >
                                            </div>
                                            <FormError :attribute_name="'phoneNumber'" :errors_form="errors"></FormError>
                                        </ValidationProvider>
                                    </div>
                                    <!-- Gross salary -->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider name="grossSalary" rules="required|numeric" tag="div" v-slot="{ errors, classes }">
                                            <div class="relative">
                                                <label for="grossSalary" :class="{ 'label': true, 'active-label': isActive('grossSalary'),'pl-7': !isActive('grossSalary') }">
                                                    Sueldo bruto
                                                </label>
                                                <vue-numeric
                                                    id="grossSalary"
                                                    ref="grossSalary"
                                                    name="grossSalary"
                                                    v-model="calculatorStore.form.grossSalary"
                                                    placeholder="$ 0"
                                                    separator="."
                                                    currency="$"
                                                    class="input-text mt-1 focus:ring-purple-900 focus:border-purple-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    :class="classes"
                                                >
                                                </vue-numeric>
                                            </div>
                                            <FormError :attribute_name="'grossSalary'" :errors_form="errors"> </FormError>
                                        </ValidationProvider>
                                    </div>
                                    <!--start date-->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider name="startDateMasked" rules="required" tag="div" v-slot="{ errors, classes }">
                                            <VueCtkDateTimePicker
                                                v-model="calculatorStore.form.startDate"
                                                :formatted="'DD-MM-YYYY'"
                                                :format="'YYYY-MM-DD'"
                                                :only-date="true"
                                                :max-date="currentDate"
                                                :button-now-translation="'Hoy'"
                                                :color="'#db3b47'"
                                                :button-color="'#db3b47'"
                                                :no-value-to-custom-elem="false"
                                                :no-button="true"
                                                :auto-close="true"
                                                position="bottom"
                                            >
                                                <input
                                                    type="text"
                                                    id="startDateMasked"
                                                    name="startDateMasked"
                                                    v-model="calculatorStore.form.startDateMasked"
                                                    placeholder="Inicio periodo trabajo:"
                                                    class="input-text mt-1 focus:ring-main focus:border-main block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    :class="{ [classes]: true, 'datepicker-label': ( !calculatorStore.form.startDate ) ? true : false }"
                                                />
                                            </VueCtkDateTimePicker>
                                            <FormError :attribute_name="'startDateMasked'" :errors_form="errors"> </FormError>
                                        </ValidationProvider>
                                    </div>
                                    <!-- TERMINOS Y CONDICIONES -->
                                    <div class="col-span-12 lg:col-span-6">
                                        <ValidationProvider  rules="required" v-slot="{ errors, classes }">
                                            <input type="checkbox"
                                                name="acceptConditions"
                                                id="acceptConditions"
                                                v-model="calculatorStore.form.acceptConditions"
                                                class="input-checkbox ml-2"
                                                :class="{ [classes]: true }"
                                            />
                                            <label class="conditions">
                                                Acepto los
                                                <a
                                                    @click.stop="showConditions"
                                                    class="text-blue-400 hover:text-blue-600 font-bold underline cursor-pointer"
                                                >
                                                    términos y condiciones
                                                </a>
                                            </label>
                                            <FormError :attribute_name="'acceptConditions'" :errors_form="errors"> </FormError>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </transition>
                            <transition enter-to-class="animated fadeInLeft" leave-to-class="animated fadeOutLeft">
                                <StepTwo v-show="calculatorStore.currentStep === 2"/>
                            </transition>
                            <transition enter-to-class="animated fadeInDown" leave-to-class="animated fadeOutUp">
                                <CompensationResult v-if="calculatorStore.currentStep === 3" :compensationItems="compensationItems"/>
                            </transition>
                        </div>
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <transition enter-to-class="animated fadeIn" leave-to-class="animated fadeOut">
                                <button
                                    v-if="calculatorStore.currentStep !== 3"
                                    id="btnCalcular"
                                    class="submit-btn" 
                                    type="submit">
                                        {{ formButtonLabel }}
                                        <span class="animate-spin spinner ease-linear rounded-full
                                            border-4 border-t-4 h-4 w-4 mt-2 ml-2"
                                            v-show="calculatorStore.showLoading"
                                        />
                                </button>
                            </transition>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import FormError from './FormError'
    import StepTwo from './StepTwo'
    import StepsNav from './StepsNav'
    import CompensationResult from './CompensationResult'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import './validations/validation.js'
    import VueCurrencyFilter from 'vue-currency-filter'
    import VueNumeric from 'vue-numeric'
    import { scroller } from 'vue-scrollto/src/scrollTo'
    import moment from 'moment'
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
    import { mapStores } from 'pinia'
    import { useCalculatorStore } from '../store/store'

    moment.locale('es')
    Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

    const scrollTo = scroller()
    const axios = require('axios')

    Vue.use(VueCurrencyFilter, {
        symbol : '$',
        thousandsSeparator: '.',
        fractionCount: 0,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true
    })

    export default {
        name: 'CalculadoraForm',
        components: {
            FormError,
            VueNumeric,
            ValidationProvider,
            ValidationObserver,
            CompensationResult,
            StepsNav,
            StepTwo
        },
        data() {
            return {
                currentDate: moment().format('YYYY-MM-DD'),
                formButtonLabel: 'Siguiente',
                showSendMessageSuccess: false,
                // response + fee charges
                uf: 33282,
                compensationItems: {
                    name: "",
                    lastname: "",
                    email: "",
                    phoneNumber: "",
                    items: [],
                    totalDemand: 0,
                    feeLabel: '',
                    fee: 0,
                    bustosLaw: 0
                }
            }
        },
        beforeMount: function() {
            axios.get('https://mindicador.cl/api/uf').then(response => {
                if( response.status == 200 ) {
                    this.uf = response.data.serie[0].valor
                }
            })
        },
        methods: {
            getCompensationOfYearsService: function() {
                console.log(this.calculatorStore.form.startDate)
                console.log(this.calculatorStore.form.grossSalary)
                const from = moment(this.calculatorStore.form.startDate)
                const to = moment()
                let [ yearsToPay, months ] = to.diff(from, 'years', true ).toFixed( 2 ).toString().split('.')
                console.log('yearsToPay', yearsToPay)
                console.log('months', months )

                if( parseInt(yearsToPay) ) {
                    if( parseInt(yearsToPay) < parseInt( 11 ) ) {
                        if( parseInt( months ) >= parseInt( 55 ) ) {
                            parseInt( yearsToPay++ )
                        }
                    }else{
                        yearsToPay = parseInt( 11 )
                    }
                } else {
                    yearsToPay = parseInt( 0 )
                }
                return this.calculatorStore.form.grossSalary * yearsToPay
            },
            getProportionalVacationsDue: function () {
                const from = moment(this.calculatorStore.form.startDate)
                const to = moment()
                let monthsToPay = to.diff( from, 'months', true ).toFixed( 2 )

                let vacationDays = ( monthsToPay * 1.75 )
                let endVacationDays = moment()
                let fraccionDia = 0

                while ( vacationDays > 0 ) {
                    if( vacationDays >= 1 ) {
                        if( !( [0,6].includes( to.dayOfWeek ) ) ) {
                            vacationDays--
                        }
                        endVacationDays.add( 1, 'days' )
                    }
                    else {
                        fraccionDia = vacationDays
                        vacationDays--
                    }
                }
                endVacationDays.hours(24)
                endVacationDays.minute(0)

                let vacationDaysReal = endVacationDays.diff( to, 'days' )
                const sueldoPorDia = this.calculatorStore.form.grossSalary / 30
                let response = 0
                if( vacationDaysReal > 42 ) {
                    vacationDaysReal = 42
                    response = vacationDaysReal * sueldoPorDia
                } else {
                    response = ( vacationDaysReal + fraccionDia ) * sueldoPorDia
                }
                return response;
            },
            showConditions: function () {
                this.$swal.fire({
                    title: 'Términos y condiciones',
                    html: "<p class='text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut <b>aliquip ex ea commodo consequat</b>. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
                    icon: 'info',
                    confirmButtonText: 'Acepto',
                    showCancelButton:"true",
                    cancelButtonText: 'Cancelar'
                }).then( ( result ) => {
                    /* Read more about isConfirmed, isDenied below */
                    if ( result.isConfirmed ) {
                        this.calculatorStore.form.acceptConditions = true
                    }
                });
            },
            isActive: function ( inputName ) {
                if( this.$refs[ inputName ] ) {
                    const value = this.$refs[ inputName ].value
                    if( value !== "" ) {
                        return true
                    }else{
                        return false
                    }
                }
                return false
            },
            validateBeforeSubmit: async function() {
                if ( this.calculatorStore.showLoading ) {
                    return
                }
                this.calculatorStore.showLoading = true
                const formCheck = await this.$refs.calculatorFormObserver.validate()
                if ( formCheck ) {
                    if ( this.calculatorStore.currentStep === 1 ) {
                        this.compensationItems.items = []
                        this.showSendMessageSuccess = false
                        this.calculatorStore.showHelpOnFail = false
                        this.calculatorStore.currentStep = 2

                        this.formButtonLabel="Calcular"
                        this.calculatorStore.showLoading = false
                    } else if ( this.calculatorStore.currentStep === 2 ) {
                        // PROCESAR Y MOSTRAR DETALLE DEL CALCULO
                        this.compensationItems.unpaidCompensationYearsAmount = this.getCompensationOfYearsService()
                        this.compensationItems.items.push({
                            'name': 'unpaidCompensationYears',
                            'title': 'Indemnización por años de servicio',
                            'amount': this.compensationItems.unpaidCompensationYearsAmount,
                            'profit': true
                        })

                        this.compensationItems.items.push({
                            'name': 'unpaidNoticeMonthCompensation',
                            'title': 'Mes de aviso',
                            'amount': this.calculatorStore.form.grossSalary,
                            'profit': true
                        })
                        
                        this.compensationItems.items.push({
                            'name': 'proportionalVacations',
                            'title': 'Vacaciones proporcionales',
                            'amount': this.getProportionalVacationsDue(),
                            'profit': true
                        })

                        this.compensationItems.legalSurcharge = ( this.compensationItems.unpaidCompensationYearsAmount + this.calculatorStore.form.grossSalary ) * 0.5;
                        this.compensationItems.items.push({
                            'name': 'legalSurcharge',
                            'title': 'Recargo legal del 50%',
                            'amount': this.compensationItems.legalSurcharge,
                            'profit': true
                        })

                        if( this.calculatorStore.unpaidWorkDaysCheck ) {
                            this.compensationItems.items.push({
                                'name': 'unpaidWorkDays',
                                'title': 'Monto de dias de trabajo no pagados',
                                'amount': this.calculatorStore.form.grossSalary / 30 * this.calculatorStore.form.unpaidWorkDays,
                                'profit': true
                            })
                        }
                        if( this.calculatorStore.unpaidBonusCheck ) {
                            this.compensationItems.items.push({
                                'name': 'unpaidBonus',
                                'title': 'Bonos no pagados',
                                'amount': this.calculatorStore.form.unpaidBonus,
                                'profit': true
                            })
                        }
                        if( this.calculatorStore.unpaidCommissionsCheck ){
                            this.compensationItems.items.push({
                                'name': 'unpaidCommissions',
                                'title': 'Monto de comisiones no pagadas',
                                'amount': this.calculatorStore.form.unpaidCommissions,
                                'profit': true
                            })
                        }
                        this.calculateDemand()
                        if( this.calculatorStore.unpaidContributionsCheck ){
                            this.compensationItems.bustosLaw = this.calculatorStore.form.grossSalary * 12
                        }
                        this.compensationItems.name = this.calculatorStore.form.firstname
                        this.compensationItems.lastname = this.calculatorStore.form.lastname
                        this.compensationItems.email = this.calculatorStore.form.email
                        this.compensationItems.phoneNumber = this.calculatorStore.form.phoneNumber
                        this.calculateFee()

                        // CONSUMIR LA API DE DEFENSA HONORARIOS PARA ENVIAR EMAIL CON INFO DEL SOLICITANTE
                        axios.post('https://calculator-backend.mejorautodespidete.cl/sendConsultingRequestMail', {
                            items: this.compensationItems,
                        }).catch(error => {
                            console.log(error);
                        })
                        .finally(() => {
                            this.calculatorStore.showLoading = false
                            this.calculatorStore.currentStep = 3
                            scrollTo( '#calculadora-response-container' )
                        });
                    }
                }
                else {
                    scrollTo( '#title' )
                }
            },
            solicitarAsesoriaClicked: function() {
                this.showSendMessageSuccess = false
                return
            },
            calculateFee: function() {
                if( this.compensationItems.items.length ) {
                    const unpaidNoticeMonthCompensation = this.compensationItems.items.filter( item => item.name === 'unpaidNoticeMonthCompensation').length ?
                        this.compensationItems.items.filter( item => item.name === 'unpaidNoticeMonthCompensation' )[0].amount : 0
                    
                    console.log(':DDD', this.compensationItems.unpaidCompensationYearsAmount);
                    const sum = this.compensationItems.unpaidCompensationYearsAmount + unpaidNoticeMonthCompensation
                    console.log('sum-->', sum);
                    console.log( 'unpaidNoticeMonthCompensation ->', unpaidNoticeMonthCompensation )
                    if ( sum >= 0 && sum <= 1499999 ) {
                        this.compensationItems.fee = this.uf * 10
                        this.compensationItems.feeLabel = 'Tasa fija de 10 UF'
                    }
                    if ( sum >= 1500000 && sum <= 1999999 ) {
                        this.compensationItems.fee = sum * 0.3
                        this.compensationItems.feeLabel = '30% del total a demandar'
                    }
                    if ( sum >= 2000000 && sum <= 2499999) {
                        this.compensationItems.fee = sum * 0.25
                        this.compensationItems.feeLabel = '25% del total a demandar'
                    }
                    if ( sum >= 2500000  && sum <= 4999999 ) {
                        this.compensationItems.fee = sum * 0.2
                        this.compensationItems.feeLabel = '20% del total a demandar'
                    }
                    if( sum >= 5000000  && sum <= 9999999 ) {
                        this.compensationItems.fee = sum * 0.15
                        this.compensationItems.feeLabel = '15% contra el pago de tu indemnización'
                    }
                    if( sum >= 10000000 ) {
                        this.compensationItems.fee = sum * 0.1
                        this.compensationItems.feeLabel = '10% contra el pago de tu indemnización'
                    }
                }
            },
            calculateDemand: function(){
                if( this.compensationItems ) {
                    this.compensationItems.totalDemand = this.compensationItems.items.map(function(item) {
                        return item.amount
                    }).reduce(function (previous, current) {
                        return previous + current
                    })
                }  
            }
        },
        watch: {
            'calculatorStore.form.grossSalary': function (val) {
                if(val == 0) {
                    this.calculatorStore.form.grossSalary = ''
                }
            },
            'calculatorStore.form.afcDiscount': function (val) {
                if(val == 0) {
                    this.calculatorStore.form.afcDiscount = ''
                }
            }, 
            'calculatorStore.form.compensationYears': function (val) {
                if(val == 0) {
                    this.calculatorStore.form.compensationYears = ''
                }
            },
            'calculatorStore.form.unpaidBonus': function ( val ) {
                if (val == 0) {
                    this.calculatorStore.form.unpaidBonus = ''
                }
            }, 
            'calculatorStore.form.unpaidCommissions': function ( val ) {
                if (val == 0) {
                    this.calculatorStore.form.unpaidCommissions = ''
                }
            },
            'calculatorStore.form.acceptConditions': function ( val ) {
                if(val == false) {
                    this.showQuestions = false
                }
            },
            'calculatorStore.form.startDate': function ( val ) {
                this.calculatorStore.form.startDateMasked = moment( val ).format( 'DD-MMMM-YYYY' )
            }
        },
        computed: {
            ...mapStores( useCalculatorStore )
        }
    }
</script>

Add "scoped" attribute to limit CSS to this component only
<style>
.spinner{
    border: solid #b9b8b8;
    border-top: solid white;
    vertical-align: middle;
}
</style>
